<!-- 会员中心 -->
<template>
    <div class="login">
        <div class="loginCard">
            <el-card>
                <div slot="header" class="header">
                    登录/注册
                </div>
                <div class="loginForm">
                    <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">
                        <el-form-item prop="u_phone">
                            <el-input v-model="form.u_phone" placeholder="请输入手机号">
                                <template slot="prepend">手机号</template></el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                            <div class="flex"> <el-input v-model="form.code" placeholder="请输入验证码">
                                    <template slot="prepend">验证码</template></el-input>

                                <el-button type="warning" @click="getCode" class="getCodeButton">{{ codeButtonDisable ?
                                    countDown + "秒" : "获取验证码" }}</el-button>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="XYCenter"><el-button style="background-color:#f83130  ; color: #fff;" @click="login"
                                    class="w100 loginButton">立即登录或注册</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                    <div class="tips">注：已有账号则立即登录，若无账号则会注册新账号</div>
                </div>
            </el-card>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            clickType: 'getCode',
            codeButtonDisable: false,
            countDown: 60,
            form: {
                u_phone: '',
                code: ''
            },
            rules: {
                u_phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
                code: [{ validator: this.validateCode, trigger: 'blur' }],
            }
        };
    },

    components: {},

    mounted() {
        this.getToken()
    },

    methods: {

        async getCode() {
            this.clickType = 'getCode'
            await this.$refs['form'].validate()
            if (this.codeButtonDisable) {
                return false
            }
            let res = await this.$axios
                .post("/api/user/verify", {
                    u_phone: this.form.u_phone,
                    type: 'login',
                })
            if (res.code == "000") {
                this.$message.success("验证码已发送，请注意查收")
                this.codeButtonDisable = true
                this.countDownStep()
            } else {
                this.$message({
                    type: 'error',
                    message: res.msg
                })
            }

        },
        async login() {
            this.clickType = 'login'
            await this.$refs['form'].validate()
            let res = await this.$axios
                .post("/api/User/logincode", this.form)
            if (res.code == '000') {
                let token = this.$store.state.token;
                window.localStorage.setItem("token", token);
                this.$store.dispatch('setIsLogin', true)
                this.$router.push({
                    path: '/memberCenter/userCenter'
                })
            } else {
                this.$message.error(res.msg)
            }

        },
        getToken() {
            this.$axios.get("/api/Auth/getToken").then((res) => {
                console.log(res);
                if (res.code == "000") {
                    this.$store.dispatch("setUserToken", res.data.token);
                }
            });
        },
        countDownStep() {
            let CountDownSetInterval = setInterval(() => {
                if (this.countDown > 0) {
                    this.countDown--;
                } else {
                    this.codeButtonDisable = false;
                    this.countDown = 60;
                    clearInterval(CountDownSetInterval);
                }
            }, 1000);
        },
        validateCode(rule, value, callback) {
            if (this.clickType == 'login') {
                if (!value) {
                    callback('请输入验证码')
                }
            }
            callback();
        },
    }
};
</script>
<style lang="scss" scoped>
.login {
    height: 850px;
    // background: #eee;
    // background-image: url('../../../assets/images/bg.jpg');
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;


    .loginCard {
        width: 35%;
        // float: right;
        // margin: 50px 100px 0 0;
    }

    .header {
        text-align: center;
        font-size: 20px;
        font-weight: 700;
    }

    .loginForm {
        // margin: 10px 30px;
        height: 400px;
    }

    .getCodeButton {
        margin-left: 10px;
        background: #f75454;
    }

    .loginButton {
        font-size: 20px;
        margin-top: 10px;
    }

    .tips {
        color: $themeColor;
    }

}
</style>