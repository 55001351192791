<!-- 会员中心 -->
<template>
  <div class="memberCenter">
    <loginCard v-if="!$store.state.isLogin"></loginCard>
    <IndividualCenter v-if="$store.state.isLogin"></IndividualCenter>
  </div>
</template>

<script>
import loginCard from './component/login.vue'
import IndividualCenter from './component/IndividualCenter.vue'
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
    };
  },

  components: { loginCard, IndividualCenter },
  computed: {
    ...mapGetters('getisLogin')
  },
  mounted() {
    console.log(this.$store.state.isLogin)
  },

  methods: {}
}

</script>
<style lang="scss" scoped>
.memberCenter {
  min-height: 850px;
}
</style>