<!-- 个人中心 -->
<template>
    <div class="IndividualCenter flex">
        <div class="sidebar">
            <el-menu :default-active="this.$route.path" class="el-menu-vertical-demo" @open="handleOpen"
                @close="handleClose" :router="true">

                <el-menu-item v-for="(item, index) in routerList" :key="index" :index="item.path">
                    <i class="el-icon-menu"></i>
                    <span slot="title">{{ item.value }}</span>
                </el-menu-item>


            </el-menu>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {
            routerList: [
                {
                    path: '/memberCenter/userCenter',
                    value: '个人中心'
                },
                // {
                //     path: '/memberCenter/ZFBConfig',
                //     value: '收款账号'
                // },
                {
                    path: '/memberCenter/withdrawal',
                    value: '我要提现'
                },
                {
                    path: '/memberCenter/withdrawalRecord',
                    value: '提现记录'
                },
                {
                    path: '/memberCenter/sellCardRecord',
                    value: '卖卡记录'
                },
                {
                    path: '/memberCenter/paymentRecord',
                    value: '打款记录'
                },
                {
                    path: '/memberCenter/realName',
                    value: '实名认证'
                },
                {
                    path: '/memberCenter/commonProblem',
                    value: '常见问题'
                },
            ]
        };
    },

    components: {},

    mounted() { },

    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        }
    }
}

</script>
<style lang="scss" scoped>
.IndividualCenter {
    margin: 30px 0 20px 20px;

    .sidebar {
        width: 200px;
        margin-right: 30px;
    }
}
</style>